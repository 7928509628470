import React, {useEffect, useState} from 'react';
import store from 'store';
import useWebSocket from 'react-use-websocket';
import axios from 'axios'

import PNLGrid from './PNLGrid'
import PortfolioDetails from './PortfolioDetails'
import Loading from './Loading'

const Dashboard = (props) => {
    const sampleTokens = {
        '0x079606234b911f15f0937105856b35f126d85a7f': {
            'Token': 'Sample Token 1',
            'In': 1.52,
            'Out': .23,
            'Net': -1.29,
            'Remaining': 1.52,
            'Realizable': 1.47,
            'Net Realizable': 1.70,
            'Realized %': -85,
            'Realizable %': 12,
            'YOLO %': 86,
            'Address': '0x079606234b911f15f0937105856b35f126d85a7f',
            'Pair Address': '0x3b9dd0ac3fa49988a177b7c020f680295fb21996',
            'Buy Time': 1621911448,
            '5m': 6,
            '10m': 17,
            '30m': 26,
            '60m': 78
        },
        '0xd277d9e3bc035468f0c2de72abab94b36ef512a5': {
            'Token': 'Sample Token 2',
            'In': 3,
            'Out': 0,
            'Net': -3,
            'Remaining': 7.5,
            'Realizable': 7.38,
            'Net Realizable': 7.38,
            'Realized %': -100,
            'Realizable %': 146,
            'YOLO %': 100,
            'Address': '0xd277d9e3bc035468f0c2de72abab94b36ef512a5',
            'Pair Address': '0x3b9dd0ac3fa49988a177b7c020f680295fb21996',
            'Buy Time': 1625646929,
            '5m': -1,
            '10m': 8,
            '30m': 20,
            '60m': 24
        },
        '0x40f86734859b01ebd95ac4e7625aae28b2dbd54c': {
            'Token': 'Sample Token 3',
            'In': 4.5,
            'Out': 1.65,
            'Net': -2.85,
            'Remaining': 2.42,
            'Realizable': 2.33,
            'Net Realizable': 3.98,
            'Realized %': -63,
            'Realizable %': -12,
            'YOLO %': 59,
            'Address': '0x40f86734859b01ebd95ac4e7625aae28b2dbd54c',
            'Pair Address': '0x3b9dd0ac3fa49988a177b7c020f680295fb21996',
            'Buy Time': 1624647929,
            '5m': 0,
            '10m': 3,
            '30m': 7,
            '60m': 18
        }
    }

    const inactiveThreshold = 0.01
    
    // const [tokenData, setTokenData] = useState(sampleTokens)
    const [bnbPrice, setBnbPrice] = useState(0)
    const [tokenData, setTokenData] = useState({})
    const [portfolioTotal, setPortfolioTotal] = useState(0)
    const [receivedData, setReceivedData] = useState(false)
    const [wallets, setWallets] = useState([])
    const [secondsSinceRefresh, setSecondsSinceRefresh] = useState(0)
    const [blacklistedTokens, setBlacklistedTokens] = useState(new Set(store.get('blacklistedTokens') || []))
    const [filterInactiveTokens, setFilterInactiveTokens] = useState(typeof store.get('filterInactiveTokens') !== 'undefined' ? store.get('filterInactiveTokens') : false)
    const [filterBlacklistedTokens, setFilterBlacklistedTokens] = useState(typeof store.get('filterBlacklistedTokens') !== 'undefined' ? store.get('filterBlacklistedTokens') : true)

    const socketUrl = 'wss://api2.apefund.io/'

    const {
        sendJsonMessage,
      } = useWebSocket(socketUrl, {
        onOpen: () => {
            sendJsonMessage(props.payload)
        },
        onMessage: (event) => updateTokens(JSON.parse(event.data)),
        //Will attempt to reconnect on all close events, such as server shutting down
        shouldReconnect: (closeEvent) => true,
      });


    useEffect(() => {
        setInterval(incrementLastRefresh, 1000);
    }, [])

    useEffect(() => {
        axios('https://api2.apefund.io/api/bnbprice')
            .then(res => {
                setBnbPrice(res.data.price)
            })
            // .catch(err => console.log(err))
    }, [])

    useEffect(() => {
        let activePortfolioTotal = 0
        Object.values(tokenData).forEach(row => {
            let allowRow = true

            // filter inactive tokens
            if(filterInactiveTokens && Number(row.Realizable) < inactiveThreshold) {
                allowRow = false
            }

            // filter blacklisted tokens
            if(filterBlacklistedTokens && blacklistedTokens.has(row.Address)) {
                allowRow = false
            }

            if (allowRow) {
                activePortfolioTotal += row.Realizable
            }
        })

        const walletsTotal = wallets.reduce((a, b) => a + Number(b.balance), 0)

        setPortfolioTotal(activePortfolioTotal + walletsTotal)
    }, [tokenData, filterInactiveTokens, filterBlacklistedTokens])


    const addTokenToBlacklist = (tokenAddress) => {
        // TODO update store in a hook
        let _blacklistedTokens = blacklistedTokens

        if (!_blacklistedTokens.has(tokenAddress)) {
            _blacklistedTokens.add(tokenAddress)

            setBlacklistedTokens(_blacklistedTokens)

            store.set('blacklistedTokens', [..._blacklistedTokens])
        }
    }

    const removeTokenFromBlacklist = (tokenAddress) => {
        // TODO update store in a hook
        let _blacklistedTokens = blacklistedTokens

        if (_blacklistedTokens.has(tokenAddress)) {
            _blacklistedTokens.delete(tokenAddress)

            setBlacklistedTokens(_blacklistedTokens)

            store.set('blacklistedTokens', [..._blacklistedTokens])
        }
    }

    const updateTokens = (message) => {
        setTokenData((tokenData) => ({ ...tokenData, ...message['tokens']}))
        setWallets(message['wallets'])
        setSecondsSinceRefresh(0)
        setReceivedData(true)
    }

    const incrementLastRefresh = () => {
        setSecondsSinceRefresh(secondsSinceRefresh => (secondsSinceRefresh + 1))
    }

    const getTokenData = (dataToParse) => {
        let parsedTokenData = {
            'blacklist': [],
            'whitelist': []
        }

        let totalIn = 0
        let totalOut = 0
        let totalNet
        let totalRemaining = 0
        let totalRealizable = 0
        let realizedPercent
        let realizablePercent
        let realizedToUnrealizedRatio
        let rowCount = 0
        let totalNetRealizable

        Object.values(dataToParse).forEach(row => {
            let allowRow = true

            // filter inactive tokens
            if(filterInactiveTokens && Number(row.Realizable) < inactiveThreshold) {
                allowRow = false
            }

            // filter blacklisted tokens
            if(filterBlacklistedTokens && blacklistedTokens.has(row.Address)) {
                allowRow = false
                parsedTokenData['blacklist'].push(row)
            }

            if(allowRow) {
                parsedTokenData['whitelist'].push(row)

                totalIn += Number(row.In)
                totalOut += Number(row.Out)
                totalRemaining += Number(row.Remaining)
                totalRealizable += Number(row.Realizable)
                rowCount++
        
                // TODO only do this once
                totalNet = totalOut - totalIn;
                realizedPercent = ((totalOut - totalIn) / totalIn) * 100;
                realizablePercent = (((totalOut + totalRealizable) - totalIn) / totalIn) * 100;
                realizedToUnrealizedRatio = (1 - (realizedPercent / realizablePercent)) * 100;
                totalNetRealizable = (totalRealizable + totalOut) - totalIn;
        
                parsedTokenData['totalsObj'] = {
                    'Token': `Totals (${rowCount} Tokens)`,
                    'In': totalIn,
                    'Out': totalOut,
                    'Net': totalNet,
                    'Remaining': totalRemaining,
                    'Net Realizable': totalNetRealizable,
                    'Realizable': totalRealizable,
                    'Realized %': realizedPercent,
                    'Realizable %': realizablePercent,
                    'YOLO %': realizedToUnrealizedRatio,
                    '5m': null,
                    '10m': null,
                    '30m': null,
                    '60m': null,
                    'Address': '',
                    'Buy Time': null,
                    'Links': ''
                }
            }
        })

        return parsedTokenData
    }

    const toggleFilterInactiveTokens = () => {
        setFilterInactiveTokens(!filterInactiveTokens)

        // TODO this may be a race condition and should be handled with an effect hook
        store.set('filterInactiveTokens', !filterInactiveTokens)
    }

    const toggleFilterBlacklistedTokens = () => {
        setFilterBlacklistedTokens(!filterBlacklistedTokens)

        // TODO this may be a race condition and should be handled with an effect hook
        store.set('filterBlacklistedTokens', !filterBlacklistedTokens)
    }

    const loggedInView = (tokenDataToParse = tokenData) => {
        return (
            <div>
                <PortfolioDetails 
                    portfolioTotal={portfolioTotal}
                    bnbPrice={bnbPrice}
                    wallets={wallets}
                    secondsSinceRefresh={secondsSinceRefresh}
                />
                <PNLGrid
                    tokenData={getTokenData(tokenDataToParse)}
                    toggleFilterInactiveTokens={toggleFilterInactiveTokens}
                    toggleFilterBlacklistedTokens={toggleFilterBlacklistedTokens}
                    filterInactiveTokens={filterInactiveTokens}
                    filterBlacklistedTokens={filterBlacklistedTokens}
                    addTokenToBlacklist={addTokenToBlacklist}
                    removeTokenFromBlacklist={removeTokenFromBlacklist}
                />
            </div>
        )
    }

    return (
        <div className='dashboard-container'>
            { 
            Object.keys(tokenData).length > 0 ?
                loggedInView() :
                receivedData ? 
                    // if we received data and the wallet has no tokens
                    // display portfolio with sample tokens
                    loggedInView(sampleTokens) :    
                    <Loading />
            }
        </div>
    )
}

export default Dashboard;