import { Button, IconButton, Dialog, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LinkIcon from '@material-ui/icons/Link';

import './Dialog.css';
import './index.css';


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


const WalletModal = props => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const copyToClipboard = str => {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    return (
        <div>
            <Button onClick={handleOpen} variant="outlined">
                {`${props.walletAddress.substring(0,4)}...${props.walletAddress.substring(37,41)}`}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="transition-modal-title" onClose={handleClose}>
                    Your Wallet
                </DialogTitle>
                <DialogContent dividers>
                    <Typography variant="h6" style={{overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        {props.walletAddress}
                    </Typography>
                    <DialogActions style={{marginBottom: '.75rem'}}>
                        <Button color="primary" style={{marginRight: '1rem'}} startIcon={<LinkIcon />}>
                            <a href={`https://etherscan.com/address/${props.walletAddress}`} target={'_blank'} rel={'noreferrer'}>
                                View on BscScan
                            </a>
                        </Button>
                        <Button onClick={copyToClipboard(props.walletAddress)} color="primary" startIcon={<FileCopyIcon />}>
                            Copy Address
                        </Button>
                    </DialogActions>
                    <Typography gutterBottom>
                        {/* BNB Balance: ¯\_(ツ)_/¯ */}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" style={{marginTop: '.5rem', marginBottom: '.75rem'}}>
                        Logout
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default WalletModal;
